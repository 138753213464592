import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import DarcBlueButton from "./components/darcblue-ui/DarcBlueButton";
import { Container } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DarcBlueText from "./components/darcblue-ui/DarcBlueText";
import { LoginButton } from './LoginButton';

const HomePage = (props) => {
    const buttonThatOpensURL = () => {
        window.open("https://www.office.com", "_blank")
    }
    const contentUrl = () => {
        window.open("https://content.drcbl.app", "_blank")
    }

    return (
        <Container align="center">
            <DarcBlueText format="headline" text="Apps" style={{ "margin": "46px 0 17.5px", "display": "block" }} />
            <DarcBlueText format="dividerLine" />
            <Container style={{ "width": "630px" }}>
                <DarcBlueText format="quote" style={{ "margin": "30px auto auto auto" }}>
                    Welcome to the App Portal. This is a place where you can find all of the apps that you need to do your job. If you have any questions, please contact the admin team.
                </DarcBlueText>
            </Container>
            <Container style={{ "width": "630px" }}>
                <DarcBlueText format="quote" style={{ "margin": "30px auto auto auto" }}>
                </DarcBlueText>
            </Container>
            <DarcBlueButton type="textButton" text="Office" onClick={buttonThatOpensURL} />
            <DarcBlueButton type="textButton" text="Content" onClick={contentUrl} />
            <Link to="/projects">
                <DarcBlueButton type="textButton" text="Projects" />
            </Link>
            <LoginButton></LoginButton>
        </Container>
    );
}

export default HomePage