import React, {useEffect, useState} from 'react'
import 'semantic-ui-css/semantic.min.css';
import ProjectCardPresentation from './ProjectCardPresentation.jsx';
import {Icon} from 'semantic-ui-react';

const ProjectCardList = (props) => {
    const [projectIndex, setProjectIndex] = useState(0);

    const handleLeft = () => {
        projectIndex === 0 ? setProjectIndex(props.list.length-1) : setProjectIndex(projectIndex-1)
    }

    const handleRight = () => {
        projectIndex === props.list.length-1 ? setProjectIndex(0) : setProjectIndex(projectIndex+1)
    }

    const arrowHandler = ({ key }) => {
        if(key === "ArrowRight") {
            handleRight()
        } else if(key === "ArrowLeft") {
            handleLeft()
        }
    }

    useEffect( () => {
        document.addEventListener("keydown", arrowHandler)

        return () => {
            document.removeEventListener("keydown", arrowHandler);
        };
    }, );

    return (
        <div align="center">
            <div align="center">
                <Icon name="left angle" color="orange" size="big" onClick={ () => handleLeft()}/>
                <Icon name="right angle" color="orange" size="big" onClick={ () => handleRight()}/>
            </div>
            <ProjectCardPresentation project={props.list[projectIndex]} dispatch={props.dispatch}/>
        </div>
    );
}

export default ProjectCardList