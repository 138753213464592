import React from "react"
import './DarcBlueText.css';

const DarcBlueText = (props) => {
    if(props.text) {
        return (
            <div className={props.format} align={props.align} {...props}>{props.text}</div>
        )
    }
    else {
        return (
            <div className={props.format} align={props.align} {...props}>{props.children}</div>
        )
    }

    
}

export default DarcBlueText