import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import DarcBlueButton from "./components/darcblue-ui/DarcBlueButton";

export const LoginButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <DarcBlueButton type="textButton" text="Login" onClick={() => handleLogin("popup")}>Sign in using Popup</DarcBlueButton>
    );
}