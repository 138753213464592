import React from 'react'
import 'semantic-ui-css/semantic.min.css';
// import { Header } from 'semantic-ui-react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
// import DarcBlueText from './components/darcblue-ui/DarcBlueText.jsx'
import './App.css'
import HomePage from "./HomePage";
import ProjectPage from "./ProjectPage";

function App() {
    return (
        <div className="App">
            <Router>
                {/* <Header className="app-header" align="center">
                    <Link to="/">
                        <DarcBlueText format="appHeadline" text="DARCBLUE Project Portfolio"/>
                    </Link>
                    <Outlet/>
                </Header> */}
                <Routes>
                    <Route path="/" element={<HomePage />}/>
                    <Route index element={<HomePage />}/>
                    <Route path="projects" element={<ProjectPage/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
