import React, {useMemo} from "react";
import {Modal, Icon, Button} from "semantic-ui-react";
import DarcBlueButton from "../darcblue-ui/DarcBlueButton";
import DarcBlueText from "../darcblue-ui/DarcBlueText";
import RAGButton from "../darcblue-ui/RAGButton";
import JoditEditor from "jodit-react";

const EditMilestonesModal = (props) => {
    const [open, setOpen] = React.useState(false)
    const [project, setProject] = React.useState(props.project)

    const config = useMemo(() => (
        {
            readonly: false,
            toolbarAdaptive: false,
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            statusbar: false,
            minHeight: "50",
            buttons: [
                'bold', 'ul', 'ol', 'fontsize',
                'paragraph', 'indent', 'outdent', 'undo', 'redo'
            ],
            removeButtons: ['break']
        }), [])

    const handleChangeMilestones = (value) => {
        setProject({...project, "milestones": value})
    }

    const handleChangeOpportunities = (value) => {
        setProject({...project, "opportunities": value})
    }

    const handleChangeRisks = (value) => {
        setProject({...project, "risks": value})
    }

    const handleChange = (e, { name, value }) => {
        setProject({...project, [name]: value})
    };

    const closeModal = () => {
        setProject(props.project)
        setOpen(false)
    }

    const handleConfirm = () => {
        if (project.history) {
            project.history.push(historyObject)
            props.dispatch({type: 'updateProject', payload: project})
        }
        else {
            console.log("No previous history")

            const newProject = project;
            newProject.history = []
            setProject(newProject)
            project.history.push(historyObject)

            console.log(project)
        }
        setOpen(false)
    }

    const historyObject =
        {
            type: "status",
            timestamp: new Date(),
            user: "Unknown", //TODO Update to actual user
            milestones: project.milestones,
            opportunities: project.opportunities,
            risks: project.risks,
            statusEngagement: project.statusEngagement,
            statusOperation: project.statusOperation
        }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button icon size="mini" compact onClick={() => setProject(props.project)}><Icon name='map pin' /></Button>}
        >
            <Modal.Header>
                <DarcBlueText format="lighterBlueSubHeadline">Edit project status</DarcBlueText>
            </Modal.Header>
            <Modal.Content>
                <div style={{"paddingBottom": "3%"}}>
                    <DarcBlueText format="lighterBlueSubHeadline">Milestones</DarcBlueText>
                    <JoditEditor
                        value={project.milestones}
                        onChange={handleChangeMilestones}
                        config={config}
                    />
                </div>

                <div style={{"paddingBottom": "3%"}}>
                    <DarcBlueText format="lighterBlueSubHeadline">Opportunities</DarcBlueText>
                    <JoditEditor
                        value={project.opportunities}
                        onChange={handleChangeOpportunities}
                        config={config}
                    />
                </div>

                <div style={{"paddingBottom": "3%"}}>
                    <DarcBlueText format="lighterBlueSubHeadline">Risks</DarcBlueText>
                    <JoditEditor
                        value={project.risks}
                        onChange={handleChangeRisks}
                        config={config}
                    />
                </div>

                <DarcBlueText format="lighterBlueSubHeadline">Statuses</DarcBlueText>
                <div>
                    <RAGButton name="statusEngagement" color={project.statusEngagement} onChange={handleChange} ragTopic="Engagement" />
                    <RAGButton name="statusOperation" color={project.statusOperation} onChange={handleChange} ragTopic="Operation"/>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <DarcBlueButton
                    type="textButton"
                    text="Cancel"
                    onClick={() => closeModal()}
                />
                <DarcBlueButton
                    type="textButton"
                    text="Confirm"
                    onClick={() => handleConfirm()}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default EditMilestonesModal