import React from 'react'
import {Card, Container, Grid, Icon} from 'semantic-ui-react'
import DarcBlueText from '../../darcblue-ui/DarcBlueText'
import './ProjectCardPresentation.css'
import KeyDataCard from './KeyDataCard'
import MilestoneCard from "./MilstoneCard";
import ProjectContextCard from "./ProjectContextCard";
import EditKeyDataModal from "../../modals/EditKeyDataModal";
import EditMilestonesModal from "../../modals/EditMilestonesModal";
import EditContextModal from "../../modals/EditContextModal";
import ViewHistoryModal from "../../modals/ViewHistoryModal";
import SDGEvaluationModal from "../../modals/SDGEvaluationModal";

const ProjectCardPresentation = (props) => (
    <Card className="presentationCardStyle">
        <Card.Content>
            <Container>
                <div style={{"display": "inline-block", "verticalAlign": "top"}}>
                    <div className="blueBox"/>
                </div>
                <div style={{"display": "inline-block"}}>
                    <DarcBlueText format="headlineCard" align="left" text={props.project.projectName}/>
                    <DarcBlueText format="greySubHeadline" text={props.project.projectShortDescription}/>
                </div>
            </Container>
            <div className="buttonRow">
                <EditKeyDataModal project={props.project} dispatch={props.dispatch}/>
                <EditMilestonesModal project={props.project} dispatch={props.dispatch}/>
                <EditContextModal project={props.project} dispatch={props.dispatch}/>
                <ViewHistoryModal project={props.project}/>
                <SDGEvaluationModal project={props.project} disparch={props.dispatch}/>
            </div>
            <Grid stackable columns="equal" style={{"marginTop": "1%"}}>
                <Grid.Row column={2} stretched>
                    <Grid.Column >
                        <KeyDataCard project={props.project}/>
                        <MilestoneCard project={props.project}/>
                    </Grid.Column>
                    <Grid.Column >
                        <ProjectContextCard project={props.project}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Card.Content extra>
                <Grid columns={6}>
                    <Grid.Column/>
                    <Grid.Column/>
                    <Grid.Column>
                        <Icon name="circle" color={props.project.statusEngagement}/>
                        <DarcBlueText format="lighterGreyText">Engagement</DarcBlueText>
                    </Grid.Column>
                    <Grid.Column>
                        <Icon name="circle" color={props.project.statusOperation}/>
                        <DarcBlueText format="lighterGreyText">Operation</DarcBlueText>
                    </Grid.Column>
                    <Grid.Column/>
                    <Grid.Column/>
                </Grid>
                <Grid columns="equal" style={{"marginTop": "3%"}}>
                    <Grid.Row column={3}>
                        <Grid.Column>
                            <Icon name="user" color="grey"/>
                            <DarcBlueText format="lighterGreyText" text={props.project.accountManager}/>
                        </Grid.Column>
                        <Grid.Column  verticalAlign="middle">
                            <Icon name="home" color="grey"/>
                            <DarcBlueText format="lighterGreyText" text="Home"/>
                        </Grid.Column>
                        <Grid.Column>
                            <Icon name="users" color="grey"/>
                            <DarcBlueText format="lighterGreyText" text={props.project.projectTeam}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card.Content>
    </Card>

)

export default ProjectCardPresentation