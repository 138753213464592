import React from "react";
import DarcBlueButton from "./darcblue-ui/DarcBlueButton";
import ExcelJS from 'exceljs'

const ExportProject = (props) => {
    const exportToExcel = (data) => {
        console.log("Converting to excel...")

        let sheetName = "Projects"
        let headerName = "RequestsList"
        let workbook = new ExcelJS.Workbook()
        let sheet = workbook.addWorksheet(sheetName, {
            views: [{ showGridLines: false}]
        })

        let columnArr = []
        for (let i in data[0]) {
            let column = { name: ""}
            column.name = i;
            columnArr.push(column)
        }

        sheet.addTable({
            name: `Header`,
            ref: "A1",
            headerRow: true,
            totalsRow: false,
            style: {
                theme: "",
                showRowStripes: false,
                showFirstColumn: true,
                width: 200
            },
            columns: [{ name: "DARCBLUE Project Portfolio"}],
            rows: [[`As of: ${Date()}`], [`Project Export`]]
        })

        sheet.addTable({
            name: headerName,
            ref: "A5",
            headerRow: true,
            totalsRow: false,
            style: {
                theme: "TableStyleMedium2",
                showRowStripes: false,
                width: 200
            },

            columns: columnArr ? columnArr : [{name: ""}],
            rows: data.map((column) => {
                let array = []
                for (let value in column) {
                    array.push(column[value])
                }
                return array
            })

        })

        sheet.getCell("A1").font = { size: 20, bold: true}

        sheet.columns.forEach(column => {
            console.log(column.letter)
            const lengths = column.values.map(value => value.toString().length);

            if(column.letter.at(0) === 'A') {
                lengths.forEach((length, index) => {
                    if(index < 4) {
                        lengths[index] = null
                    }
                })
            }

            const width = Math.max(...lengths.filter(value => typeof value === 'number'));

            if (width > 50) {
                column.width = 50;
            }
            else {
                column.width = width
            }

            console.log(column.width)

        });

        const writeToFile = (fileName, content) => {
            const link = document.createElement("a")
            const blob = new Blob([content], {
                type: "application/vnd.ms-excel;charset=utf-8;"
            })
            link.download = fileName
            link.target = "_blank"
            link.rel = "noopener noreferrer"
            link.href = URL.createObjectURL(blob)
            link.click()
        }

        console.log(data)

        workbook.xlsx.writeBuffer().then((buffer) => {
            writeToFile("project_data.xlsx", buffer)
        })
    }

    const mapToTable = (currentList) => {
        let columnHeaders = [
            {
                "key": "id",
                "text": "ID",
                "value": "id"
            },
            {
                "key": "projectName",
                "text": "Project Name",
                "value": "projectName"
            },
            {
                "key": "projectShortDescription",
                "text": "Project Short Description",
                "value": "projectShortDescription"
            },
            {
                "key": "customer",
                "text": "Customer",
                "value": "customer"
            },
            {
                "key": "start",
                "text": "Start Date",
                "value": "start"
            },
            {
                "key": "timespan",
                "text": "Timespan",
                "value": "timesspan"
            },
            {
                "key": "engagement",
                "text": "Engagement",
                "value": "engagement"
            },
            {
                "key": "accountManager",
                "text": "Account Manager",
                "value": "accountManager"
            },
            {
                "key": "accountManager",
                "text": "Account Manager",
                "value": "accountManager"
            },
            {
                "key": "projectTeam",
                "text": "Project Team",
                "value": "projectTeam"
            },
            {
                "key": "milestones",
                "text": "Milestones",
                "value": "milestones"
            },
            {
                "key": "opportunities",
                "text": "Opportunities",
                "value": "opportunities"
            },
            {
                "key": "risks",
                "text": "Risks",
                "value": "risks"
            },
            {
                "key": "projectContext",
                "text": "Project Context",
                "value": "projectContext"
            },
            {
                "key": "tasks",
                "text": "Tasks",
                "value": "tasks"
            },
            {
                "key": "valueDelivered",
                "text": "Value Delivered",
                "value": "valueDelivered"
            },
            {
                "key": "statusEngagement",
                "text": "Status of Engagement",
                "value": "statusEngagement"
            },
            {
                "key": "statusOperation",
                "text": "Status of Operation",
                "value": "statusOperation"
            },
            {
                "key": "history",
                "text": "History",
                "value": "history"
            },
        ]

        return currentList.map((listItem) => {
            let output = {}

            for (let column in columnHeaders) {
                if (columnHeaders[column].key === "id") {
                    output = {...output, "ID": parseInt(listItem.id)}
                }
                if (columnHeaders[column].key === "projectName") {
                    output = {...output, "Project Name": listItem.projectName}
                }
                if (columnHeaders[column].key === "projectShortDescription") {
                    output = {...output, "Project Short Description": listItem.projectShortDescription}
                }
                if (columnHeaders[column].key === "customer") {
                    output = {...output, "Customer": listItem.customer}
                }
                if (columnHeaders[column].key === "start") {
                    output = {...output, "Start Date": listItem.start}
                }
                if (columnHeaders[column].key === "timespan") {
                    output = {...output, "Timespan": listItem.timespan}
                }
                if (columnHeaders[column].key === "engagement") {
                    output = {...output, "Engagement": listItem.engagement}
                }
                if (columnHeaders[column].key === "accountManager") {
                    output = {...output, "Account Manager": listItem.accountManager}
                }
                if (columnHeaders[column].key === "projectTeam") {
                    output = {...output, "Project Team": listItem.projectTeam}
                }
                if (columnHeaders[column].key === "milestones") {
                    output = {...output, "Milestones": listItem.milestones}
                }
                if (columnHeaders[column].key === "opportunities") {
                    output = {...output, "Opportunities": listItem.opportunities}
                }
                if (columnHeaders[column].key === "risks") {
                    output = {...output, "Risks": listItem.risks}
                }
                if (columnHeaders[column].key === "projectContext") {
                    output = {...output, "Project Context": listItem.projectContext}
                }
                if (columnHeaders[column].key === "tasks") {
                    output = {...output, "Tasks": listItem.tasks}
                }
                if (columnHeaders[column].key === "valueDelivered") {
                    output = {...output, "Value Delivered": listItem.valueDelivered}
                }
                if (columnHeaders[column].key === "statusEngagement") {
                    output = {...output, "Status of Engagement": listItem.statusEngagement}
                }
                if (columnHeaders[column].key === "statusOperation") {
                    output = {...output, "Status of Operation": listItem.statusOperation}
                }
                if (columnHeaders[column].key === "history") {
                    output = {...output, "History": listItem.history}
                }
            }
            return output
        })
    }

    return (
        <DarcBlueButton icon="file excel" type="iconButton" onClick={() => exportToExcel(mapToTable(props.currentList))} compact/>
    )
}

export default ExportProject