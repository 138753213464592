import React from 'react'
import {Card, Grid, Icon} from "semantic-ui-react";
import DarcBlueText from "../../darcblue-ui/DarcBlueText";
import './ProjectCardSmall.css'
import EditKeyDataModal from "../../modals/EditKeyDataModal";
import EditMilestonesModal from "../../modals/EditMilestonesModal";
import EditContextModal from "../../modals/EditContextModal";
import ViewHistoryModal from "../../modals/ViewHistoryModal";
import SDGEvaluationModal from "../../modals/SDGEvaluationModal";

const ProjectCardSmall = (props) => (
    <Card className="smallCardStyle">
        <Card.Content>
            <Card.Content extra>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <DarcBlueText format="lighterGreyTextSmall" align="left" text={"Start date: " + props.project.start}/>
                        </Grid.Column>
                        <Grid.Column align="right">
                            <EditKeyDataModal project={props.project} dispatch={props.dispatch}/>
                            <EditMilestonesModal project={props.project} dispatch={props.dispatch}/>
                            <EditContextModal project={props.project} dispatch={props.dispatch}/>
                            <ViewHistoryModal project={props.project}/>
                            <SDGEvaluationModal project={props.project} dispatch={props.dispatch}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
            <Card.Content style={{"margin": "12px 0"}}>
                <DarcBlueText format="headlineSmall" text={props.project.projectName}/>
                <DarcBlueText format="greySubHeadlineSmall" text={props.project.projectShortDescription} style={{"margin": "6px 0 12px"}}/>
                <DarcBlueText format="greyText" align="left">
                    <DarcBlueText format="blueUppercaseText">
                        <Icon name="building"/>Customer
                    </DarcBlueText>
                    {" " + props.project.customer} <br/>

                    <DarcBlueText format="blueUppercaseText">
                        <Icon name="user"/>Project manager
                    </DarcBlueText>
                    {" " + props.project.accountManager} <br/>

                    <DarcBlueText format="blueUppercaseText">
                        <Icon name="user"/>Project team
                    </DarcBlueText>
                    {" " + props.project.projectTeam}
                </DarcBlueText>
            </Card.Content>
            <Card.Content extra>
                <Grid columns={4}>
                    <Grid.Column/>
                    <Grid.Column>
                        <Icon name="circle" color={props.project.statusEngagement}/>
                        <DarcBlueText format="lighterGreyTextSmall" text="Engagement"/>
                    </Grid.Column>
                    <Grid.Column>
                        <Icon name="circle" color={props.project.statusOperation}/>
                        <DarcBlueText format="lighterGreyTextSmall" text="Operation"/>
                    </Grid.Column>
                    <Grid.Column/>
                </Grid>
            </Card.Content>
        </Card.Content>
    </Card>
)

export default ProjectCardSmall