import React, { useEffect, useReducer, useState } from "react";
import "semantic-ui-css/semantic.min.css";
import { Container, Grid, Image } from "semantic-ui-react";
import DarcBlueButton from "./components/darcblue-ui/DarcBlueButton";
import SDGWheelBW from "./assets/SDG Wheel_Transparent_WEB_bw.png";
import NewProjectModal from "./components/modals/NewProjectModal";
import ExportProject from "./components/ExportProject";
import ProjectCardSmallList from "./components/cards/small/ProjectCardSmallList";
import ProjectCardList from "./components/cards/presentation/ProjectCardList";
import ProjectCardTableView from "./components/tableview/ProjectCardTableView";
import ProjectCardSDGList from "./components/cards/sustainability/ProjectCardSDGList";
import { appReducer } from "./state/AppReducer";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { LoginButton } from "./LoginButton";

const ProjectPage = (props) => {
  const testList = [
    {
      id: "2",
      projectName: "Sample",
      projectShortDescription: "SMILE - ATCOM Implementation",
      customer: "Autombile example, from large company",
      start: "01.01.2019",
      timespan: "39 months",
      engagement: "03-2022",
      accountManager: "MB",
      projectTeam: "SG",
      milestones:
        "<ul><li><strong>31.05.2022</strong>: Bookability from: 03/22 - 05/22 - takeover by line " +
        "organization</li><li>Go Live successfully: 11/20 &amp; 03/21 &amp; 06/21 &amp; 09/21&amp; 12/21</li>" +
        "<li><strong>09-2021</strong>: resource usability until June 2022</li></ul>",
      opportunities: "Project extended for<strong> fall 2022</strong>.",
      risks: "SG leaving DARCBLUE.",
      projectContext:
        "<ul><li>Handling difficult project phase.</li><li>Closure of relevant system gaps (Budget: &gt;5000 MD; already planned " +
        "8000 MD).</li></ul>",
      tasks:
        "<ul><li>Execution of critical roles: PMO/ Projectmanagement</li><li>Ensure project plan for " +
        "01.11.23, 01.03.23 " +
        "subsidiaries.</li></ul>",
      valueDelivered:
        "<p>With<strong> standardization</strong> the cost and competivness will be " +
        "significantly increased (time to market, business model, process efficiency, " +
        "flexibility).</p>",
      statusEngagement: "green",
      statusOperation: "green",
      history: [],
      evaluationSDG: [
        "Offer employment",
        null,
        null,
        "Learn at work",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        "<strong>Lorem ipsum</strong> " +
          "dolor sit amet, consectetur adipiscing elit. Pellentesque accumsan dolor dolor, a mattis ex suscipit " +
          "vitae. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. " +
          "Integer gravida nec orci maximus tempus.",
      ],
    },
    {
      id: "3",
      projectName: "Example innovation AG",
      projectShortDescription: "Project Center – Project Cards",
      customer: "Vestlandske Innovasjonsselskap AS",
      start: "02.06.2021",
      timespan: "31.12.2022",
      engagement: "12/2022, 120+ hours",
      accountManager: "EO",
      projectTeam: "EO, MES, JM",
      milestones: "<strong>Project:</strong> Production (Medio January 2022)",
      opportunities:
        "Use experience for further DTE projects, use internally, use open source base for further service business",
      risks: "No risks",
      projectContext:
        "<ul><li>Implementation of project center with project cards in Azure.</li> <li>Integration to Unit 4 and " +
        "Power BI.</li><li> User interface for Portfolio Management.</li></ul>",
      tasks:
        "<ul><li>Design of Architecture</li><li>Implementation of DevOps Tool Chain</li><li>Implementation of Solution</li></ul>",
      valueDelivered:
        "<ul><li>DevOps</li><li> Simplified Project Portfolio Management</li></ul>",
      statusEngagement: "yellow",
      statusOperation: "green",
      history: [],
      evaluationSDG: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        "<ul><li>Cooperate</li><li>Be nice to others</li></ul>",
      ],
    },
    {
      id: "4",
      projectName: "Dummy project I",
      projectShortDescription: "A standard project",
      customer: "Dummy AS",
      start: "01.01.2021",
      timespan: "12 months",
      engagement: "31.12.2022",
      accountManager: "AH",
      projectTeam: "OS, HI",
      milestones:
        "<ol><li>First milestone within 01.09.2022</li><li>Second milestone before 31.11.2022</li></ol>",
      opportunities: "New projects",
      risks: "<ul><li>Risk 1</li><li>Risk 2</li></ul>",
      projectContext: "<p> This is the very short context of this project.</p>",
      tasks:
        "<p>Task 1</p><ul><li>Task 2</li><ul><li>Subtask 1</li><li>Subtask 2</li></ul></ul>",
      valueDelivered: "<p>Some value will be delivered</p>",
      statusEngagement: "red",
      statusOperation: "yellow",
      history: [],
      evaluationSDG: ["Fight poverty in all forms."],
    },
    {
      id: "5",
      projectName: "Dummy project II",
      projectShortDescription: "Another standard project",
      customer: "Dummy AS",
      start: "01.01.2023",
      timespan: "12 months",
      engagement: "31.12.2023",
      accountManager: "MM",
      projectTeam: "OS, AI",
      milestones:
        "<ol><li>Milestone #1 within 01.09.2022</li><li>Milestone #2 before 31.11.2022</li></ol>",
      opportunities: "New projects",
      risks: "<ul><li>First risk</li><li>Second risk</li></ul>",
      projectContext: "<p> This is the very short context of this project.</p>",
      tasks:
        "<p>Task 1</p><ul><li>Task 2</li><ul><li>Subtask 1</li><li>Subtask 2</li></ul></ul>",
      valueDelivered: "<p>Some value will be delivered</p>",
      statusEngagement: "red",
      statusOperation: "yellow",
      history: [],
      evaluationSDG: [null, null, "Ensure healthy lives."],
    },
  ];

  const [reload, setReload] = useState(0);
  const [cardView, setCardView] = useState(0);
  const [appState, dispatch] = useReducer(appReducer, {
    list: [...testList],
  });

  useEffect(() => {
    (async function () {
      console.log("Reloading " + reload);
      try {
        const { projectList } = await (
          await fetch(`/api/DbGetProjects`)
        ).json();
        dispatch({ type: "updateProjectList", payload: projectList });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [reload]);

  const updateCardView = (viewValue) => {
    setCardView(viewValue);
  };

  return (
    <Container>
      <AuthenticatedTemplate>
        <Grid centered>
          <Grid.Row>
            <DarcBlueButton
              icon="th"
              type="iconButton"
              onClick={() => updateCardView(0)}
              compact
            />
            <DarcBlueButton
              icon="square"
              type="iconButton"
              onClick={() => updateCardView(1)}
              compact
            />
            <DarcBlueButton
              icon="table"
              type="iconButton"
              onClick={() => updateCardView(2)}
              compact
            />
            <DarcBlueButton
              icon
              type="iconButton"
              onClick={() => updateCardView(3)}
              compact
            >
              <Image src={SDGWheelBW} width="25px" />
            </DarcBlueButton>
            <NewProjectModal dispatch={dispatch} />
            <DarcBlueButton
              icon="sync"
              type="iconButton"
              onClick={() => setReload(reload + 1)}
              compact
            />
            <ExportProject currentList={appState.list} />
          </Grid.Row>
        </Grid>

        {cardView === 0 && (
          <Grid centered style={{ marginTop: "15px", marginBottom: "3%" }}>
            <ProjectCardSmallList list={appState.list} dispatch={dispatch} />
          </Grid>
        )}

        {cardView === 1 && (
          <Grid centered style={{ marginTop: "15px", marginBottom: "3%" }}>
            <ProjectCardList list={appState.list} dispatch={dispatch} />
          </Grid>
        )}

        {cardView === 2 && (
          <Grid centered style={{ marginTop: "15px", marginBottom: "3%" }}>
            <ProjectCardTableView list={appState.list} />
          </Grid>
        )}

        {cardView === 3 && (
          <Grid centered style={{ marginTop: "15px", marginBottom: "3%" }}>
            <ProjectCardSDGList list={appState.list} dispatch={dispatch} />
          </Grid>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginButton></LoginButton>
      </UnauthenticatedTemplate>
    </Container>
  );
};

export default ProjectPage;
