import React, {useState} from 'react'
import { Button } from 'semantic-ui-react'

const nextColor = (statusColor) => {
  switch (statusColor) {
    case "green":
      return "yellow";
    case "yellow":
      return "red";
    default:
      return "green";
  }
}

const RAGButton = (props) => {
  const initialColor = props.color
  const [status, setStatus] = useState(initialColor);

  const onClick = () => {
    const newColor = nextColor(status)
    setStatus(newColor)
    const event = { name: props.name, value: newColor}
    props.onChange(status, event)
  }


  return (
        <Button color={status} size="mini" onClick={() => onClick()} compact>{props.ragTopic}</Button>
  );
}

export default RAGButton