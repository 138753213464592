import React, {useMemo} from "react";
import {Modal, Icon, Button} from "semantic-ui-react";
import DarcBlueButton from "../darcblue-ui/DarcBlueButton";
import DarcBlueText from "../darcblue-ui/DarcBlueText";
import JoditEditor from "jodit-react";

const EditContextModal = (props) => {
    const [open, setOpen] = React.useState(false)
    const [project, setProject] = React.useState(props.project)

    const config = useMemo(() => (
        {
            readonly: false,
            toolbarAdaptive: false,
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            statusbar: false,
            minHeight: "50",
            buttons: [
                'bold', 'ul', 'ol', 'fontsize',
                'paragraph', 'indent', 'outdent', 'undo', 'redo'
            ],
            removeButtons: ['break']
        }), []
    )

    const historyObject = {
        type: "status",
        timestamp: new Date(),
        user: "Unknown", //TODO Update to actual user
        milestones: project.milestones,
        opportunities: project.opportunities,
        risks: project.risks,
        statusEngagement: project.statusEngagement,
        statusOperation: project.statusOperation
    }

    const handleChangeContext = (value) => {
        setProject({...project, "projectContext": value})
    }

    const handleChangeTasks = (value) => {
        setProject({...project, "tasks": value})
    }

    const handleChangeValue = (value) => {
        setProject({...project, "valueDelivered": value})
    }

    const closeModal = () => {
        setProject(props.project)
        setOpen(false)
    }

    const handleConfirm = () => {
        if (project.history) {
            project.history.push(historyObject)
            props.dispatch({type: 'updateProject', payload: project})
        }
        else {
            console.log("No previous history")

            const newProject = project;
            newProject.history = []
            setProject(newProject)
            project.history.push(historyObject)

            console.log(project)
        }
        setOpen(false)
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button icon size="mini" compact onClick={() => setProject(props.project)}><Icon name='globe' /></Button>}
        >
            <Modal.Header>
                <DarcBlueText format="lighterBlueSubHeadline">Edit project context, tasks and value delivered</DarcBlueText>
            </Modal.Header>
            <Modal.Content>
                <div style={{"paddingBottom": "3%"}}>
                    <DarcBlueText format="lighterBlueSubHeadline">Project Context</DarcBlueText>
                    <JoditEditor
                        value={project.projectContext}
                        onChange={handleChangeContext}
                        config={config}
                    />
                </div>

                <div style={{"paddingBottom": "3%"}}>
                    <DarcBlueText format="lighterBlueSubHeadline">Tasks for DARCBLUE</DarcBlueText>
                    <JoditEditor
                        value={project.tasks}
                        onChange={handleChangeTasks}
                        config={config}
                    />
                </div>

                <div style={{"paddingBottom": "3%"}}>
                    <DarcBlueText format="lighterBlueSubHeadline">Digital Business Value Delivered</DarcBlueText>
                    <JoditEditor
                        value={project.valueDelivered}
                        onChange={handleChangeValue}
                        config={config}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <DarcBlueButton
                    type="textButton"
                    text="cancel"
                    onClick={() => closeModal()}
                />
                <DarcBlueButton
                    type="textButton"
                    text="save"
                    onClick={() => handleConfirm()}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default EditContextModal