import React from "react";
import {Container, Icon, Table} from "semantic-ui-react";

const ProjectCardTableView = (props) => {
    return (
        <Container>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Start Date</Table.HeaderCell>
                        <Table.HeaderCell>Project Name</Table.HeaderCell>
                        <Table.HeaderCell>Short Description</Table.HeaderCell>
                        <Table.HeaderCell>Account Manager</Table.HeaderCell>
                        <Table.HeaderCell>Team</Table.HeaderCell>
                        <Table.HeaderCell>Customer</Table.HeaderCell>
                        <Table.HeaderCell>Status of engagement</Table.HeaderCell>
                        <Table.HeaderCell>Status of operation</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        props.list.map((oneProject) =>
                            <Table.Row key={oneProject.id}>
                                <Table.Cell>
                                    {oneProject.id}
                                </Table.Cell>
                                <Table.Cell>
                                    {oneProject.start}
                                </Table.Cell>
                                <Table.Cell>
                                    {oneProject.projectName}
                                </Table.Cell>
                                <Table.Cell>
                                    {oneProject.projectShortDescription}
                                </Table.Cell>
                                <Table.Cell>
                                    {oneProject.accountManager}
                                </Table.Cell>
                                <Table.Cell>
                                    {oneProject.projectTeam}
                                </Table.Cell>
                                <Table.Cell>
                                    {oneProject.customer}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    <Icon name="circle" color={oneProject.statusEngagement}/>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    <Icon name="circle" color={oneProject.statusOperation}/>
                                </Table.Cell>
                            </Table.Row>)
                    }
                </Table.Body>
            </Table>
        </Container>
    )
}

export default ProjectCardTableView