import React from "react";
import { Card } from "semantic-ui-react";
import DarcBlueText from "../../darcblue-ui/DarcBlueText";
import ReactHtmlParser from "html-react-parser";

const ProjectContextCard = (props) => (
  <Card className="subCardStyle">
    <DarcBlueText format="blueSubHeadline">Project Context</DarcBlueText>
    <DarcBlueText format="greyTextCard" align="left">
      {ReactHtmlParser(props.project.projectContext)}
    </DarcBlueText>
    <br />
    <DarcBlueText format="blueSubHeadline">Tasks for DARCBLUE</DarcBlueText>
    <DarcBlueText format="greyTextCard" align="left">
      {ReactHtmlParser(props.project.tasks)}
    </DarcBlueText>
    <br />
    <DarcBlueText format="blueSubHeadline">
      Digital Business Value Delivered
    </DarcBlueText>
    <DarcBlueText format="greyTextCard" align="left">
      {ReactHtmlParser(props.project.valueDelivered)}
    </DarcBlueText>
  </Card>
);

export default ProjectContextCard;
