import React from "react";
import { Card } from "semantic-ui-react";
import DarcBlueText from "../../darcblue-ui/DarcBlueText";
import ReactHtmlParser from "html-react-parser";

const MilestoneCard = (props) => (
  <Card className="subCardStyle">
    <DarcBlueText format="blueSubHeadline">Milestones</DarcBlueText>
    <DarcBlueText format="greyTextCard" align="left">
      {ReactHtmlParser(props.project.milestones)}
    </DarcBlueText>
    <br />
    <DarcBlueText format="blueSubHeadline">Opportunities</DarcBlueText>
    <DarcBlueText format="greyTextCard" align="left">
      {ReactHtmlParser(props.project.opportunities)}
    </DarcBlueText>
    <br />
    <DarcBlueText format="blueSubHeadline">Risks</DarcBlueText>
    <DarcBlueText format="greyTextCard" align="left">
      {ReactHtmlParser(props.project.risks)}
    </DarcBlueText>
  </Card>
);

export default MilestoneCard;
