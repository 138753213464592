const appReducer = (state, action) => {
    let listData = state.list
    switch (action.type) {
        case 'addNewProject':
            const newProject = action.payload
            listData = [ newProject, ...listData ]
            return { ...state, list: listData }
        case 'updateProject':
            const changedProject = action.payload
            listData = listData.map((project) => project.id === changedProject.id ? changedProject : project)
            return { ...state, list: listData }
        case 'updateProjectList':
            return { ...state, list: action.payload }
        default:
            throw new Error(`${action.type} is not a know action type.`)
    }
}
export { appReducer }