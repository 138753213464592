import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import { Container, Grid } from 'semantic-ui-react';
import ProjectCardSmall from "./ProjectCardSmall";

const ProjectCardSmallList = (props) => {
    return (
        <Container>
            <Grid stackable columns={2}>
                {props.list.map((oneProject) => (
                    <Grid.Column key={oneProject.id}>
                        <ProjectCardSmall project={oneProject} dispatch={props.dispatch}/>
                    </Grid.Column>
                ))}
            </Grid>
        </Container>
    );
}

export default ProjectCardSmallList