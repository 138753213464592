import React from "react";
import { Card, Grid, Image } from "semantic-ui-react";
import DarcBlueText from "../../darcblue-ui/DarcBlueText";
import "./ProjectCardSDG.css";
import Goal1 from "../../../assets/E_WEB_01.png";
import Goal2 from "../../../assets/E_WEB_02.png";
import Goal3 from "../../../assets/E_WEB_03.png";
import Goal4 from "../../../assets/E_WEB_04.png";
import Goal5 from "../../../assets/E_WEB_05.png";
import Goal6 from "../../../assets/E_WEB_06.png";
import Goal7 from "../../../assets/E_WEB_07.png";
import Goal8 from "../../../assets/E_WEB_08.png";
import Goal9 from "../../../assets/E_WEB_09.png";
import Goal10 from "../../../assets/E_WEB_10.png";
import Goal11 from "../../../assets/E_WEB_11.png";
import Goal12 from "../../../assets/E_WEB_12.png";
import Goal13 from "../../../assets/E_WEB_13.png";
import Goal14 from "../../../assets/E_WEB_14.png";
import Goal15 from "../../../assets/E_WEB_15.png";
import Goal16 from "../../../assets/E_WEB_16.png";
import Goal17 from "../../../assets/E_WEB_17.png";
import ReactHtmlParser from "html-react-parser";
import SDGEvaluationModal from "../../modals/SDGEvaluationModal";

const images = [
  Goal1,
  Goal2,
  Goal3,
  Goal4,
  Goal5,
  Goal6,
  Goal7,
  Goal8,
  Goal9,
  Goal10,
  Goal11,
  Goal12,
  Goal13,
  Goal14,
  Goal15,
  Goal16,
  Goal17,
];

const goals = [
  "No poverty",
  "Zero hunger",
  "Good health and well-being",
  "Quality education",
  "Gender equality",
  "Clean water and sanitation",
  "Affordable and clean energy",
  "Decent work and economic growth",
  "Industry, innovation and infrastructure",
  "Reduced inequalities",
  "Sustainable cities and communities",
  "Responsible consumption and production",
  "Climate action",
  "Life below water",
  "Life on land",
  "Peace, justice and strong institutions",
  "Partnerships for the goals",
];

const ProjectCardSDG = (props) => (
  <Card className="SDGCardStyle">
    <Card.Content>
      <Card.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={1} />
            <Grid.Column width={14} style={{ padding: "0" }}>
              <DarcBlueText
                format="headlineSmall"
                text={props.project.projectName}
              />
            </Grid.Column>
            <Grid.Column width={1} align="right" style={{ padding: "0" }}>
              <SDGEvaluationModal
                project={props.project}
                dispatch={props.dispatch}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <DarcBlueText
          format="greySubHeadlineSmall"
          text={props.project.projectShortDescription}
          style={{ margin: "6px 0 12px" }}
        />
        <Grid columns="equal" divided>
          {props.project.evaluationSDG.map(
            (goal, index) =>
              goal && (
                <Grid.Row key={index}>
                  <Grid.Column width={4}>
                    <Image
                      src={images[index]}
                      width="110px"
                      height="auto"
                      rounded
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <DarcBlueText
                      format="blueSubHeadline"
                      text={goals[index]}
                      align="left"
                    />
                    <DarcBlueText
                      format="greyText"
                      align="left"
                      text={ReactHtmlParser(goal)}
                    />
                  </Grid.Column>
                </Grid.Row>
              )
          )}
        </Grid>
      </Card.Content>
    </Card.Content>
  </Card>
);

export default ProjectCardSDG;
