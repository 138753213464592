import React from "react";
import {Modal, Input} from "semantic-ui-react";
import DarcBlueButton from "../darcblue-ui/DarcBlueButton";
import DarcBlueText from "../darcblue-ui/DarcBlueText";

const NewProjectModal = (props) => {
    const emptyProject = {id: "", projectName: "", projectShortDescription: "", customer: "",
        start: "", timespan: "", engagement: "", accountManager: "", projectTeam: "", milestones: "", opportunities: "",
        risks: "", projectContext: "", tasks: "", valueDelivered: "", statusEngagement: "grey", statusOperation: "grey",
        history: [], evaluationSDG: []}


    const [open, setOpen] = React.useState(false)
    const [project, setProject] = React.useState(emptyProject,)

    const changeHandler = (event) => {
        setProject({...project, [event.target.name]: event.target.value})
    }

    const closeModal = () => {
        setOpen(false)
        setProject(emptyProject)
    }

    async function addProject() {
        try {
            const {createdItem} = await (await fetch(`/api/DbAddNewProject`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(project)
                })

            )
            props.dispatch({type: 'addNewProject', payload: createdItem})
        } catch (err) {
            console.log(err.message)
        }
        closeModal()
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<DarcBlueButton type="textButton" text="New project"/>}
        >
            <Modal.Header>New project</Modal.Header>
            <Modal.Content>
                <DarcBlueText format="greyText">ID</DarcBlueText> {/*Temporary: Enter ID for new projects to avoid problems with missing ID. */}
                <Input name="id" onChange={changeHandler}/>
                <DarcBlueText format="greyText">Title</DarcBlueText>
                <Input name="projectName" onChange={changeHandler}/>
                <DarcBlueText format="greyText">Short description</DarcBlueText>
                <Input name="projectShortDescription" onChange={changeHandler}/>
                <DarcBlueText format="greyText">Customer</DarcBlueText>
                <Input name="customer" onChange={changeHandler}/>
                <DarcBlueText format="greyText">Start of execution (dd.mm.yyyy)</DarcBlueText>
                <Input name="start" onChange={changeHandler}/>
                <DarcBlueText format="greyText" >Estimated timespan</DarcBlueText>
                <Input name="timespan" onChange={changeHandler}/>
                <DarcBlueText format="greyText" >Engagement until (mm.yyyy)</DarcBlueText>
                <Input name="engagement" onChange={changeHandler}/>
                <DarcBlueText format="greyText" >Account manager (NN)</DarcBlueText>
                <Input name="accountManager" onChange={changeHandler}/>
                <DarcBlueText format="greyText" >Project team (NN)</DarcBlueText>
                <Input name="projectTeam" onChange={changeHandler}/>
            </Modal.Content>
            <Modal.Actions>
                <DarcBlueButton
                    type="textButton"
                    text="close"
                    onClick={() => closeModal()}
                />
                <DarcBlueButton
                    type="textButton"
                    text="add"
                    onClick={() => addProject()}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default NewProjectModal