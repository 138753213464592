import React, { useMemo } from "react";
import { Button, Divider, Grid, Image, Modal, Popup } from "semantic-ui-react";
import DarcBlueButton from "../darcblue-ui/DarcBlueButton";
import JoditEditor from "jodit-react";
import DarcBlueText from "../darcblue-ui/DarcBlueText";
import Goal1 from "../../assets/E_WEB_01.png";
import Goal2 from "../../assets/E_WEB_02.png";
import Goal3 from "../../assets/E_WEB_03.png";
import Goal4 from "../../assets/E_WEB_04.png";
import Goal5 from "../../assets/E_WEB_05.png";
import Goal6 from "../../assets/E_WEB_06.png";
import Goal7 from "../../assets/E_WEB_07.png";
import Goal8 from "../../assets/E_WEB_08.png";
import Goal9 from "../../assets/E_WEB_09.png";
import Goal10 from "../../assets/E_WEB_10.png";
import Goal11 from "../../assets/E_WEB_11.png";
import Goal12 from "../../assets/E_WEB_12.png";
import Goal13 from "../../assets/E_WEB_13.png";
import Goal14 from "../../assets/E_WEB_14.png";
import Goal15 from "../../assets/E_WEB_15.png";
import Goal16 from "../../assets/E_WEB_16.png";
import Goal17 from "../../assets/E_WEB_17.png";
import Goal1Inv from "../../assets/E_WEB_INVERTED_01.png";
import Goal2Inv from "../../assets/E_WEB_INVERTED_02.png";
import Goal3Inv from "../../assets/E_WEB_INVERTED_03.png";
import Goal4Inv from "../../assets/E_WEB_INVERTED_04.png";
import Goal5Inv from "../../assets/E_WEB_INVERTED_05.png";
import Goal6Inv from "../../assets/E_WEB_INVERTED_06.png";
import Goal7Inv from "../../assets/E_WEB_INVERTED_07.png";
import Goal8Inv from "../../assets/E_WEB_INVERTED_08.png";
import Goal9Inv from "../../assets/E_WEB_INVERTED_09.png";
import Goal10Inv from "../../assets/E_WEB_INVERTED_10.png";
import Goal11Inv from "../../assets/E_WEB_INVERTED_11.png";
import Goal12Inv from "../../assets/E_WEB_INVERTED_12.png";
import Goal13Inv from "../../assets/E_WEB_INVERTED_13.png";
import Goal14Inv from "../../assets/E_WEB_INVERTED_14.png";
import Goal15Inv from "../../assets/E_WEB_INVERTED_15.png";
import Goal16Inv from "../../assets/E_WEB_INVERTED_16.png";
import Goal17Inv from "../../assets/E_WEB_INVERTED_17.png";
import SDGWheelBW from "../../assets/SDG Wheel_Transparent_WEB_bw.png";
import ReactHtmlParser from "html-react-parser";
import "../darcblue-ui/Divider.css";

const SDGEvaluationModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const [project, setProject] = React.useState(props.project);
  const [goalIndex, setGoalIndex] = React.useState(null);
  const [editorOpen, setEditorOpen] = React.useState(false);

  const images = [
    Goal1,
    Goal2,
    Goal3,
    Goal4,
    Goal5,
    Goal6,
    Goal7,
    Goal8,
    Goal9,
    Goal10,
    Goal11,
    Goal12,
    Goal13,
    Goal14,
    Goal15,
    Goal16,
    Goal17,
  ];

  const imagesInv = [
    Goal1Inv,
    Goal2Inv,
    Goal3Inv,
    Goal4Inv,
    Goal5Inv,
    Goal6Inv,
    Goal7Inv,
    Goal8Inv,
    Goal9Inv,
    Goal10Inv,
    Goal11Inv,
    Goal12Inv,
    Goal13Inv,
    Goal14Inv,
    Goal15Inv,
    Goal16Inv,
    Goal17Inv,
  ];

  const goals = [
    "No poverty",
    "Zero hunger",
    "Good health and well-being",
    "Quality education",
    "Gender equality",
    "Clean water and sanitation",
    "Affordable and clean energy",
    "Decent work and economic growth",
    "Industry, innovation and infrastructure",
    "Reduced inequalities",
    "Sustainable cities and communities",
    "Responsible consumption and production",
    "Climate action",
    "Life below water",
    "Life on land",
    "Peace, justice and strong institutions",
    "Partnerships for the goals",
  ];

  const goalDescr = [
    "Economic growth must be inclusive to provide sustainable jobs and promote equality.",
    "The food and agriculture sector offers key solutions for development, and is central for hunger and poverty eradication.",
    "Ensuring healthy lives and promoting the well-being for all at all ages is essential to sustainable development.",
    "Obtaining a quality education is the foundation to improving people’s lives and sustainable development.",
    "Gender equality is not only a fundamental human right, but a necessary foundation for a peaceful, prosperous " +
      "and sustainable world.",
    "Clean, accessible water for all is an essential part of the world we want to live in.",
    "Energy is central to nearly every major challenge and opportunity.",
    "Sustainable economic growth will require " +
      "societies to create the conditions that allow people to have quality jobs.",
    "Investments in infrastructure are " +
      "crucial to achieving sustainable development.",
    "To reduce inequalities, policies should be universal in " +
      "principle, paying attention to the needs of disadvantaged and marginalized populations.",
    "There needs to " +
      "be a future in which cities provide opportunities for all, with access to basic services, energy, housing, " +
      "transportation and more.",
    "Responsible Production and Consumption",
    "Climate change is a global challenge " +
      "that affects everyone, everywhere.",
    "Careful management of this essential global resource is a key feature " +
      "of a sustainable future.",
    "Sustainably manage forests, combat desertification, halt and reverse land " +
      "degradation, halt biodiversity loss",
    "Access to justice for all, and building effective, accountable " +
      "institutions at all levels.",
    "Revitalize the global partnership for sustainable development.",
  ];

  const config = useMemo(
    () => ({
      readonly: false,
      toolbarAdaptive: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      statusbar: false,
      minHeight: "50",
      buttons: [
        "bold",
        "ul",
        "ol",
        "fontsize",
        "paragraph",
        "indent",
        "outdent",
        "undo",
        "redo",
      ],
      removeButtons: ["break"],
    }),
    []
  );

  const closeModal = () => {
    setEditorOpen(false);
    setGoalIndex(null);
    setOpen(false);
  };

  const changeHandler = (value) => {
    let newArr = [...project.evaluationSDG];
    newArr[goalIndex] = value;

    setProject({ ...project, evaluationSDG: newArr });
  };

  const handleConfirmButton = () => {
    props.dispatch({ type: "updateProject", payload: project });
    closeModal();
  };

  const clickGoalImage = (index) => {
    if (index === goalIndex) {
      setEditorOpen(false);
      setGoalIndex(null);
    } else {
      setEditorOpen(false);
      setGoalIndex(index);
      setEditorOpen(true);
    }
  };

  const goBack = () => {
    setGoalIndex(null);
    setProject(props.project);
  };

  const viewImage = (index) => {
    return goalIndex === index ? (
      <Image
        src={imagesInv[index]}
        width="130px"
        rounded
        onClick={() => clickGoalImage(index)}
        key={index + 1}
      />
    ) : (
      <Image
        src={images[index]}
        width="130px"
        rounded
        onClick={() => clickGoalImage(index)}
        key={index + 1}
      />
    );
  };

  const goalExists = (index) => {
    return !(
      project.evaluationSDG[index] === null ||
      project.evaluationSDG[index] === undefined ||
      project.evaluationSDG[index].match(/^ *$/) !== null
    );
  };

  const checkSDGList = () => {
    setProject(props.project);

    if (!project.evaluationSDG) {
      const newProject = project;
      newProject.evaluationSDG = [];
      setProject(newProject);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
          icon
          size="mini"
          style={{ height: "23.94px", width: "25.91px", verticalAlign: "top" }}
          compact
          onClick={() => checkSDGList()}
        >
          <Image
            src={SDGWheelBW}
            width="12px"
            height="12px"
            verticalAlign="top"
          />
        </Button>
      }
    >
      <Modal.Header style={{ padding: "15px 21px" }}>
        <DarcBlueText format="lighterBlueSubHeadline">
          Evaluation of the Sustainable Development Goals (UN)
        </DarcBlueText>
      </Modal.Header>
      {project.evaluationSDG && !goalIndex && goalIndex !== 0 && (
        <Modal.Content>
          <Grid columns={6}>
            <Grid.Row>
              {goals.map(
                (image, index) =>
                  index < 6 && (
                    <Popup
                      key={index}
                      trigger={
                        <Grid.Column>
                          {viewImage(index)}
                          {goalExists(index) && <Divider className="bolder" />}
                        </Grid.Column>
                      }
                      content={
                        <DarcBlueText
                          format="greyText"
                          text={ReactHtmlParser(
                            project.evaluationSDG[index] || ""
                          )}
                        />
                      }
                      position="bottom center"
                      open={goalExists(index) ? undefined : false}
                      wide
                    />
                  )
              )}
            </Grid.Row>
            <Grid.Row>
              {goals.map(
                (goal, index) =>
                  index >= 6 &&
                  index < 12 && (
                    <Popup
                      key={index}
                      trigger={
                        <Grid.Column>
                          {viewImage(index)}
                          {goalExists(index) && <Divider className="bolder" />}
                        </Grid.Column>
                      }
                      content={
                        <DarcBlueText
                          format="greyText"
                          text={ReactHtmlParser(
                            project.evaluationSDG[index] || ""
                          )}
                        />
                      }
                      position="top center"
                      open={goalExists(index) ? undefined : false}
                      wide
                    />
                  )
              )}
            </Grid.Row>
            <Grid.Row>
              {goals.map(
                (goal, index) =>
                  index >= 12 &&
                  index < 17 && (
                    <Popup
                      key={index}
                      trigger={
                        <Grid.Column>
                          {viewImage(index)}
                          {goalExists(index) && <Divider className="bolder" />}
                        </Grid.Column>
                      }
                      content={
                        <DarcBlueText
                          format="greyText"
                          text={ReactHtmlParser(
                            project.evaluationSDG[index] || ""
                          )}
                        />
                      }
                      position="top center"
                      open={goalExists(index) ? undefined : false}
                      wide
                    />
                  )
              )}
            </Grid.Row>
          </Grid>
        </Modal.Content>
      )}
      {project.evaluationSDG && (goalIndex || goalIndex === 0) && (
        <Modal.Content>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column width={3}>
                <Image src={images[goalIndex]} width="150px" rounded />
              </Grid.Column>
              <Grid.Column>
                {editorOpen === true && (
                  <div>
                    <DarcBlueText
                      format="greySubHeadline"
                      style={{ margin: "6px 6px 6px 0" }}
                    >
                      {goalIndex + 1 + " " + goals[goalIndex]}
                    </DarcBlueText>
                    <DarcBlueText
                      format="greyText"
                      text={goalDescr[goalIndex]}
                    />
                    <JoditEditor
                      value={project.evaluationSDG[goalIndex] || ""}
                      onChange={changeHandler}
                      config={config}
                    />
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      )}
      <Modal.Actions>
        {project.evaluationSDG && (goalIndex || goalIndex === 0) && (
          <DarcBlueButton
            type="textButton"
            text="Back"
            onClick={() => goBack()}
          />
        )}
        <DarcBlueButton
          type="textButton"
          text="Cancel"
          onClick={() => closeModal()}
        />
        {project.evaluationSDG && (goalIndex || goalIndex === 0) && (
          <DarcBlueButton
            type="textButton"
            text="Confirm"
            onClick={() => handleConfirmButton()}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default SDGEvaluationModal;
