import React from "react";
import {Modal, Input, Icon, Button} from "semantic-ui-react";
import DarcBlueButton from "../darcblue-ui/DarcBlueButton";
import DarcBlueText from "../darcblue-ui/DarcBlueText";

const EditKeyDataModal = (props) => {

    const [open, setOpen] = React.useState(false)
    const [project, setProject] = React.useState(props.project)

    const changeHandler = (event) => {
        setProject({...project, [event.target.name]: event.target.value})
    }

    const closeModal = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        props.dispatch({ type: 'updateProject', payload: project })
        closeModal()
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button icon size="mini" compact onClick={() => setProject(props.project)}><Icon name='key' /></Button>}
        >
            <Modal.Header style={{"padding": "15px 21px"}}>
                <DarcBlueText format="lighterBlueSubHeadline">Edit key data</DarcBlueText>
            </Modal.Header>
            <Modal.Content>
                <DarcBlueText format="lighterBlueSubHeadline">Title</DarcBlueText>
                <Input name="projectName" placeholder="Project title" style={{"margin": "1px 0 15px", "width": "200px"}} onChange={changeHandler} defaultValue={props.project.projectName}/>

                <DarcBlueText format="lighterBlueSubHeadline">Short description</DarcBlueText>
                <Input name="projectShortDescription" placeholder="Short description" style={{"margin": "1px 0 15px", "width": "300px"}} onChange={changeHandler} defaultValue={props.project.projectShortDescription}/>

                <DarcBlueText format="lighterBlueSubHeadline">Customer</DarcBlueText>
                <Input name="customer" placeholder="Customer" style={{"margin": "1px 0 15px", "width": "300px"}} onChange={changeHandler} defaultValue={props.project.customer}/>

                <DarcBlueText format="lighterBlueSubHeadline">Start of execution</DarcBlueText>
                <Input name="start" placeholder="dd.mm.yyyy" style={{"margin": "1px 0 15px", "width": "105px"}} onChange={changeHandler} defaultValue={props.project.start}/>

                <DarcBlueText format="lighterBlueSubHeadline" >Estimated timespan</DarcBlueText>
                <Input name="timespan" placeholder="E.g. 2 years" style={{"margin": "1px 0 15px", "width": "105px"}} onChange={changeHandler} defaultValue={props.project.timespan}/>

                <DarcBlueText format="lighterBlueSubHeadline" >Engagement until</DarcBlueText>
                <Input name="engagement" placeholder="mm.yyyy" style={{"margin": "1px 0 15px", "width": "105px"}} onChange={changeHandler} defaultValue={props.project.engagement}/>

                <DarcBlueText format="lighterBlueSubHeadline" >Account manager</DarcBlueText>
                <Input name="accountManager" placeholder="NN" style={{"margin": "1px 0 15px", "width": "53px"}} onChange={changeHandler} defaultValue={props.project.accountManager}/>

                <DarcBlueText format="lighterBlueSubHeadline" >Project team</DarcBlueText>
                <Input name="projectTeam" placeholder="NN, NN, NN"  style={{"margin": "1px 0 15px", "width": "105px"}} onChange={changeHandler} defaultValue={props.project.projectTeam}/>
            </Modal.Content>
            <Modal.Actions>
                <DarcBlueButton
                    type="textButton"
                    text="Cancel"
                    onClick={() => closeModal()}
                />
                <DarcBlueButton
                    type="textButton"
                    text="Confirm"
                    onClick={() => handleConfirm()}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default EditKeyDataModal