import React from 'react'
import {Card} from "semantic-ui-react";
import DarcBlueText from "../../darcblue-ui/DarcBlueText";

const KeyDataCard = (props) => (
    <Card className="subCardStyle">
        <DarcBlueText format="blueSubHeadline" text="Key data"/>
        <DarcBlueText format="greyTextCard" align="left">
            <DarcBlueText format="orangeUppercaseText">Customer</DarcBlueText> <span>{props.project.customer}</span><br/>
            <DarcBlueText format="orangeUppercaseText">Start of execution</DarcBlueText> <span>{props.project.start}</span><br/>
            <DarcBlueText format="orangeUppercaseText">Estimated timespan</DarcBlueText> <span>{props.project.timespan}</span><br/>
            <DarcBlueText format="orangeUppercaseText">Currently ordered until</DarcBlueText> <span>{props.project.engagement}</span>
        </DarcBlueText>
    </Card>
)

export default KeyDataCard