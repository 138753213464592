import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import ProjectCardSDG from './ProjectCardSDG.jsx';
import { Container, Grid } from 'semantic-ui-react';

const ProjectCardSDGList = (props) => {
    return (
        <Container>
            <Grid stackable columns={2}>
                {
                    props.list.map((oneProject) => (
                        (oneProject.evaluationSDG && oneProject.evaluationSDG.length !== 0) &&
                        <Grid.Column key={oneProject.id}>
                            <ProjectCardSDG project={oneProject} dispatch={props.dispatch}/>
                        </Grid.Column>)
                    )
                }
            </Grid>
        </Container>
    );
}

export default ProjectCardSDGList