import React from "react"
import { Button } from "semantic-ui-react"
import './DarcBlueButton.css';

const DarcBlueButton = (props) => {
        if(props.text) {
            return (
                <Button className={props.type} {...props}>{props.text}</Button>
            )
        }
        else {
            return (
                <Button className={props.type} {...props}>{props.children}</Button>
            )
        }

}

export default DarcBlueButton