import React from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import DarcBlueText from "../darcblue-ui/DarcBlueText";
import ReactHtmlParser from "html-react-parser";
import DarcBlueButton from "../darcblue-ui/DarcBlueButton";

const ViewHistoryModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const project = props.project;

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button icon size="mini" compact>
          <Icon name="history" />
        </Button>
      }
    >
      <Modal.Header style={{ padding: "15px 21px" }}>
        <DarcBlueText format="lighterBlueSubHeadline">History</DarcBlueText>
      </Modal.Header>
      <Modal.Content>
        {project.history && (
          <DarcBlueText format="greyText">
            Times changed: {project.history.length}
          </DarcBlueText>
        )}
        {!project.history && (
          <DarcBlueText format="greyText">
            There is no history yet.
          </DarcBlueText>
        )}
        <Table celled>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Time of change</Table.HeaderCell>
              <Table.HeaderCell>User</Table.HeaderCell>
              <Table.HeaderCell>Milestones</Table.HeaderCell>
              <Table.HeaderCell>Opportunities</Table.HeaderCell>
              <Table.HeaderCell>Risks</Table.HeaderCell>
              <Table.HeaderCell>Status of engagement</Table.HeaderCell>
              <Table.HeaderCell>Status of operation</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {project.history &&
              project.history.map((oneHistoryObject, i) => (
                <Table.Row key={i}>
                  <Table.Cell textAlign="center">
                    {oneHistoryObject.timestamp.toLocaleString()}
                  </Table.Cell>
                  <Table.Cell>{oneHistoryObject.user}</Table.Cell>
                  <Table.Cell>
                    {ReactHtmlParser(oneHistoryObject.milestones)}
                  </Table.Cell>
                  <Table.Cell>
                    {ReactHtmlParser(oneHistoryObject.opportunities)}
                  </Table.Cell>
                  <Table.Cell>
                    {ReactHtmlParser(oneHistoryObject.risks)}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Icon
                      name="circle"
                      color={oneHistoryObject.statusEngagement}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Icon
                      name="circle"
                      color={oneHistoryObject.statusOperation}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <DarcBlueButton
          type="textButton"
          text="Close"
          onClick={() => setOpen(false)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ViewHistoryModal;
